import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@services/config.service';
import { UserSessionService } from '@services/user-session.service';

@Component({
  selector: 'app-favorite-vehicles',
  templateUrl: './favorite-vehicles.component.html',
  styleUrls: ['./favorite-vehicles.component.sass']
})
export class FavoriteVehiclesComponent implements OnInit {

  constructor(
  	public userSessionService: UserSessionService,
  	private configService: ConfigService,
  ) { }

  ngOnInit() {
  	// Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();
  }

}
