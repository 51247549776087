import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';
import { FavoriteVehiclesRoutingModule } from './favorite-vehicles-routing.module';
import { FavoriteVehiclesComponent } from './favorite-vehicles.component';


@NgModule({
  declarations: [FavoriteVehiclesComponent],
  imports: [
    CommonModule,
    VehicleSearchSharedModule,
    FavoriteVehiclesRoutingModule
  ]
})
export class FavoriteVehiclesModule { }
